<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="row mb-2">
            <div class="col-md-6 text-start">
              <h3>
                {{ account.name }}
              </h3>
            </div>
            <div class="col-md-6 text-end mt-3">
              <h5>
                <span v-if="account.balance"
                  ><span class="currency_code">GH₵</span>
                  <b v-if="account.balance > 0" class="text-success">
                    {{ numberWithCommas(account.balance) }}</b
                  >
                  <b v-if="account.balance < 0" class="text-danger">
                    {{ numberWithCommas(account.balance) }}</b
                  >
                  <b v-if="account.balance == 0">
                    {{ numberWithCommas(account.balance) }}</b
                  >
                </span>
              </h5>
            </div>
          </div>
          <div v-if="account.transactions.data.length === 0 && !loading.page">
            <CenterMessage message="No transactions found."></CenterMessage>
          </div>
          <div v-else class="text-start">
            <b-table
              :responsive="true"
              small
              hover
              v-if="!loading.page"
              :items="account.transactions.data"
              :fields="fields"
            >
              <template #cell(amount)="data">
                <div class="text-nowrap">
                  <span v-if="data.item.amount"
                    ><span class="currency_code">GH₵</span>
                    <b v-if="data.item.amount > 0" class="text-success">
                      {{ numberWithCommas(data.item.amount) }}</b
                    >
                    <b v-if="data.item.amount < 0" class="text-danger">
                      {{ numberWithCommas(data.item.amount) }}</b
                    >
                    <b v-if="data.item.amount == 0">
                      {{ numberWithCommas(data.item.amount) }}</b
                    >
                  </span>
                </div>
              </template>
              <template #cell(invoice)="data">
                <div>
                  <span v-if="data.item.invoice">{{
                    `Invoice #${data.item.invoice.id} - GHS ${data.item.invoice.total}`
                  }}</span>
                  <span v-else class="text-muted text-sm">n/a </span>
                </div>
              </template>
              <template #cell(date)="data">
                <div class="text-nowrap">
                  <span
                    >{{
                      moment(data.item.created_at).format(
                        "dddd MMMM Do YYYY, h:mm a"
                      )
                    }}
                  </span>
                </div>
              </template>
            </b-table>
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import AccountService from "@/services/AccountService";
import CenterMessage from "@/components/CenterMessage.vue";

export default {
  name: "ViewAccountsView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: ["id", "amount", "description", "invoice", "date"],
      account: {},
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },

  async mounted() {
    await this.getAccount();
    this.loading.page = false;
  },

  methods: {
    async getAccount(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await AccountService.getAccount(
        this.$route.params.id,
        this.pagination.currentPage,
        this.pagination.perPage
      );

      this.account = response.data.account;
      this.pagination.totalRows = response.data.account.transactions.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.account.transactions.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getAccount();
      }
    },
  },
};
</script>