<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="row mb-2">
            <div class="col-md-12 text-start">
              <h4>C{{ client.id + " - " + client.name }}</h4>
            </div>
            <div class="col-md-12 text-start mt-2">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <h6>Details</h6>
                  <div class="ng-card">
                    <b-alert
                      class="mb-2"
                      variant="success"
                      :show="success !== ''"
                      >{{ success }}</b-alert
                    >
                    <b-alert
                      class="mb-2"
                      variant="danger"
                      :show="errors.length > 0"
                    >
                      <ul>
                        <li v-for="error in errors" :key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </b-alert>
                    <b-form @submit.prevent="onSubmit">
                      <b-form-group
                        class="required"
                        id="code-group"
                        label="Code"
                        label-for="code"
                      >
                        <b-form-input
                          :disabled="true"
                          class="mb-3"
                          id="code"
                          v-model="client.code"
                          type="text"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="required"
                        id="name-group"
                        label="Name"
                        label-for="name"
                      >
                        <b-form-input
                          :disabled="loading.form"
                          class="mb-3"
                          id="name"
                          v-model="form.name"
                          type="text"
                          placeholder="Enter the client's name."
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="required"
                        id="description-group"
                        label="Description"
                        label-for="description"
                      >
                        <b-form-input
                          :disabled="loading.form"
                          class="mb-3"
                          id="description"
                          v-model="form.description"
                          type="text"
                          placeholder="Client's tagline or description."
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="hourly_rate-group"
                        label="Hourly Rate"
                        label-for="hourly_rate"
                      >
                        <b-form-input
                          :disabled="loading.form"
                          class="mb-3"
                          id="hourly_rate"
                          v-model="form.hourly_rate"
                          type="text"
                          placeholder="Client's charging rate per hour."
                        ></b-form-input>
                      </b-form-group>
                      <div class="text-end">
                        <b-button
                          size="sm"
                          :disabled="loading.form"
                          type="submit"
                          variant="primary"
                          >Update Client</b-button
                        >
                      </div>
                    </b-form>
                  </div>
                </div>
                <div class="col-md-8 mt-2">
                  <div class="row">
                    <div class="col-md-6 text-start mt-2">
                      <h6>User</h6>
                    </div>
                    <div class="col-md-6 text-end mb-1">
                      <a :href="`/clients/${client.id}/users/add`"
                        ><b-button size="sm" class="fw-bold" variant="primary">
                          New User
                        </b-button></a
                      >
                    </div>
                  </div>
                  <div>
                    <div class="ng-card" style="min-height: 200px">
                      <div v-if="client.users.length === 0 && !loading.page">
                        <div class="ng-center-100">No users found.</div>
                      </div>
                      <div v-else class="text-start">
                        <b-table
                          :responsive="true"
                          small
                          hover
                          v-if="!loading.page"
                          :items="client.users"
                          :fields="user_fields"
                        >
                          <template #cell(phone)="data">
                            <div>
                              <span v-if="data.item.phone">
                                {{ data.item.phone }}
                              </span>
                              <span v-else class="text-muted text-sm"
                                >Not set.
                              </span>
                            </div>
                          </template>
                          <template #cell(action)="data">
                            <div>
                              <a
                                :href="
                                  '/clients/' +
                                  client.id +
                                  '/users/' +
                                  data.item.id
                                "
                                >View</a
                              >
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6 text-start mt-2">
                      <h6>Service Level Agreements</h6>
                    </div>
                    <div class="col-md-6 text-end mb-1">
                      <a
                        :href="`/clients/${client.id}/service-level-agreements/add`"
                        ><b-button size="sm" class="fw-bold" variant="primary">
                          New SLA
                        </b-button></a
                      >
                    </div>
                  </div>
                  <div>
                    <div class="ng-card" style="min-height: 100px">
                      <div
                        v-if="
                          client.service_level_agreements.length === 0 &&
                          !loading.page
                        "
                      >
                        <div class="ng-center-100">No SLAs found.</div>
                      </div>
                      <div v-else class="text-start">
                        <b-table
                          :responsive="true"
                          small
                          hover
                          v-if="!loading.page"
                          :items="client.service_level_agreements"
                          :fields="sla_fields"
                        >
                          <template #cell(status)="data">
                            <div>
                              {{ data.item.status.name }}
                            </div>
                          </template>
                          <template #cell(frequency)="data">
                            <div>
                              {{ data.item.billing_period.name }}
                            </div>
                          </template>
                          <template #cell(monthly)="data">
                            <div class="fw-bold">
                              {{ numberWithCommas(data.item.monthly_payment) }}
                            </div>
                          </template>
                          <template #cell(period)="data">
                            <div class="text-success fw-bold">
                              <span
                                v-if="
                                  data.item.billing_period_code == 'monthly'
                                "
                                >{{
                                  numberWithCommas(data.item.monthly_payment)
                                }}</span
                              >
                              <span
                                v-if="
                                  data.item.billing_period_code == 'quarterly'
                                "
                                >{{
                                  numberWithCommas(
                                    data.item.monthly_payment * 3
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  data.item.billing_period_code == 'bi_yearly'
                                "
                                >{{
                                  numberWithCommas(
                                    data.item.monthly_payment * 6
                                  )
                                }}</span
                              >
                              <span
                                v-if="data.item.billing_period_code == 'yearly'"
                                >{{
                                  numberWithCommas(
                                    data.item.monthly_payment * 12
                                  )
                                }}</span
                              >
                            </div>
                          </template>
                          <template #cell(due)="data">
                            <div>
                              <span
                                >{{
                                  moment(data.item.next_billing_date).format(
                                    "dddd MMMM Do YYYY"
                                  )
                                }}
                              </span>
                            </div>
                          </template>
                          <template #cell(action)="data">
                            <div>
                              <a
                                :href="
                                  '/clients/' +
                                  client.id +
                                  '/service-level-agreements/' +
                                  data.item.id
                                "
                                >View</a
                              >
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import ClientService from "@/services/ClientService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "ViewClientView",
  components: {
    CustomLoader,
  },
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      client: {},
      user_fields: ["id", "name", "email", "phone", "action"],
      sla_fields: [
        "id",
        "status",
        "monthly",
        "frequency",
        "period",
        "due",
        "action",
      ],
      form: {
        name: "",
        description: "",
        hourly_rate: "",
      },
      success: "",
      errors: [],
    };
  },

  async mounted() {
    await this.getClient();
    document.title = `Staff - C${this.client.id} - ${this.client.name}`;
    this.loading.page = false;
  },

  methods: {
    async getClient() {
      let response = await ClientService.getClient(this.$route.params.id);
      this.client = response.data.client;
      this.form.name = this.client.name;
      this.form.description = this.client.description;
      this.form.hourly_rate = this.client.hourly_rate;
    },

    async onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Build payload
      ClientService.updateClient(this.$route.params.id, this.form)
        .then((response) => {
          this.success = response.data.message;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>