import axios from "axios";

export const AuthClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

export default {

  async generateToken(payload){
    await AuthClient.get("/sanctum/csrf-cookie");
    return AuthClient.post("/api/login", {
      email: payload.email,
      password: payload.password,
      device_name: navigator.userAgent
    });
  },
};
