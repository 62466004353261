<template>
    <div>
      <CenterMessage
        message="Resource not found."
      ></CenterMessage>
    </div>
  </template>
    
    <script>
  import CenterMessage from "@/components/CenterMessage.vue";
  
  export default {
    name: "NotAuthorizedView",
    components: {
      CenterMessage,
    },
  };
  </script>
    