import { ApiService } from "./APIService";

export default {
    getTrackingEntries(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null,
        staffCode = null,
        startDate = null,
        endDate = null
    ) {
        // Generate url
        let url = 'api/staff/time-entries?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        if (staffCode) {
            url += '&staff_code=' + staffCode;
        }

        if (startDate) {
            url += '&start_date=' + startDate;
        }

        if (endDate) {
            url += '&end_date=' + endDate;
        }

        return ApiService.get(url);
    },

    getTimeEntry(tracking_entry_id) {
        return ApiService.get("api/staff/time-entries/" + tracking_entry_id);
    },

    getLastTimeEntry() {
        return ApiService.get("api/staff/time-entries/last");
    },

    startTracking(payload) {
        return ApiService.post("api/staff/time-entries/start", payload);
    },

    endTracking(timeEntryId) {
        return ApiService.get(`api/staff/time-entries/${timeEntryId}/end`);
    },

    updateTrackingEntry(tracking_entry_id, payload) {
        return ApiService.post("api/staff/time-entries/" + tracking_entry_id, payload);
    }
}