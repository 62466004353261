import { ApiService } from "./APIService";

export default {
    getInvoices(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null,
        statusCode = null,
        typeCode = null
    ) {
        // Generate url
        let url = 'api/staff/invoices?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        if (statusCode) {
            url += '&status_code=' + statusCode;
        }

        if (typeCode) {
            url += '&type_code=' + typeCode;
        }

        return ApiService.get(url);
    },

    getInvoice(invoice_id) {
        return ApiService.get("api/staff/invoices/" + invoice_id);
    },

    addInvoice(payload) {
        return ApiService.post("api/staff/invoices/add", payload);
    },

    updateInvoice(invoice_id, payload) {
        return ApiService.post("/api/staff/invoices/" + invoice_id + "/update", payload);
    }
}