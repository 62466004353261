import { ApiService } from "./APIService";

export default {
    getAccounts(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null
    ) {
        // Generate url
        let url = 'api/staff/accounts?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        return ApiService.get(url);
    },

    getAccount(account_id, page, pageSize) {
        // Generate url
        let url = "api/staff/accounts/" + account_id + "?";

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;
        return ApiService.get(url);
    },

    recordTransaction(payload) {
        return ApiService.post("api/staff/accounts/record-transaction", payload);
    }
}