<template>
  <div id="app">
    <div>
      <b-navbar
        v-if="$store.getters['user/user']"
        toggleable="lg"
        type="dark"
        variant="dark"
        class="mb-5"
      >
        <div class="container">
          <b-navbar-brand href="/">
            <img
              src="@/assets/logo-staff-portal-on-black-horizontal.png"
              height="35"
              alt="Nusoft Group Staff Portal Logo - On Black"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mx-auto">
              <b-nav-item v-if="userHasClearance(4)" href="/tickets"
                >Tickets</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(3)" href="/clients"
                >Clients</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(3)" href="/projects"
                >Projects</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(4)" href="/sites"
                >Sites</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(2)" href="/staff"
                >Staff</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(4)" href="/time-entries"
                >Time</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(2)" href="/invoices"
                >Invoices</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(2)" href="/accounts"
                >Accounts</b-nav-item
              >
              <b-nav-item v-if="userHasClearance(2)" href="/settings"
                >Settings</b-nav-item
              >
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-form-select
                style="width: 200px"
                class="mt-1"
                v-model="timer.selected_ticket"
                :options="tickets"
              ></b-form-select>
              <b-nav-item
                v-if="timer.is_tracking"
                href="/"
                @click.prevent="stopTimeTracking"
                >{{ timer.time }} - Stop
              </b-nav-item>
              <b-nav-item
                v-if="timer.selected_ticket && !timer.is_tracking"
                href="/"
                @click.prevent="startTimeTracking"
                >Start</b-nav-item
              >
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
    <router-view />
    <div style="margin-bottom: 100px"></div>
    <footer class="fixed-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6" style="font-size: 12px">
            © Nusoft Group
            {{ new Date().getFullYear() != "2024" ? "2024 - " : "" }}
            {{ new Date().getFullYear() }}
            <span v-if="$store.getters['user/user']"
              >| {{ $store.getters["user/user"].email }} -
              <a href="" @click.prevent="logout" style="color: red"
                >Logout</a
              ></span
            >
          </div>
          <div class="col-md-6" style="font-size: 12px">
            Need help with this system? Call <b>059 701 8036</b>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import TicketService from "./services/TicketService";
import TimeTrackingService from "./services/TimeTrackingService";

export default {
  mounted() {
    if (this.$store.getters["user/user"]) {
      this.getTickets();
      this.initializeTimeTracking();
    }
  },
  data() {
    return {
      selected: "",
      tickets: [{ value: "", text: " -- Select a Ticket -- " }],
      timer: {
        selected_ticket: "",
        is_tracking: false,
        tracked_time: 70,
        time: "00:00:00",
      },
      timeEntry: null,
    };
  },
  methods: {
    async getTickets() {
      let response = await TicketService.getTickets(
        1,
        1000000,
        "title",
        "desc"
      );

      this.tickets = [
        this.tickets[0],
        ...response.data.tickets.data
          .filter((item) => {
            return (
              (item.status_code == "pending" ||
                item.status_code == "ongoing") &&
              item.assigned_time > 0
            );
          })
          .map((item) => {
            return {
              text: `T${item.id} - ${item.client.name} - ${item.title}`,
              value: `${item.id}`,
            };
          }),
      ];
    },

    async startTimeTracking() {
      TimeTrackingService.startTracking({
        ticket_id: this.timer.selected_ticket,
      }).then((response) => {
        // Update timer
        this.timer.tracked_time = response.data.time_entry.duration;
        this.timer.is_tracking = true;
        this.timeEntry = response.data.time_entry;
      });
    },

    async stopTimeTracking() {
      TimeTrackingService.endTracking(this.timeEntry.id).then(() => {
        // Update timer
        this.timer.is_tracking = false;
      });
    },

    async initializeTimeTracking() {
      // Get last time entry.
      {
        TimeTrackingService.getLastTimeEntry().then((response) => {
          // Update timer
          if (response.data.time_entry != null) {
            this.timer.tracked_time = response.data.time_entry.duration;

            this.timeEntry = response.data.time_entry;

            this.timer.selected_ticket = response.data.time_entry.ticket.id;

            if (response.data.time_entry.end_time == null) {
              this.timer.is_tracking = true;
            } else {
              this.timer.is_tracking = false;
              if (
                !(
                  response.data.time_entry.ticket.status_code == "pending" ||
                  response.data.time_entry.ticket.status_code == "ongoing"
                )
              ) {
                this.timer.selected_ticket = "";
              }
            }
          } else {
            this.timer.is_tracking = false;
          }
        });
      }

      // Initialize Timer
      this.updateTime();
      setInterval(this.updateTime, 1000);
    },

    updateTime() {
      if (this.timer.is_tracking == true) {
        this.timer.tracked_time += 1;
        const tracked_time = new Date(
          1970,
          0,
          1,
          0,
          0,
          this.timer.tracked_time
        );
        const hours = tracked_time.getHours();
        const minutes = tracked_time.getMinutes();
        const seconds = tracked_time.getSeconds();

        // Format the string with leading zeroes
        this.timer.time = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      }
    },

    logout() {
      this.$store.dispatch("user/logout");
      this.$router.push("/login");
    },
  },
};
</script>


<style>
@import "./assets/styles/main.css";
</style>
