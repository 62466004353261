<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>Record Transaction</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <b-form-group
                  class="mb-3 mt-3 required"
                  id="account-group"
                  label="Account"
                  label-for="account"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="account"
                    v-model="form.account_id"
                    :options="options.accounts"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="mb-3 mt-3"
                  id="invoice-group"
                  label="Invoice"
                  label-for="invoice"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="invoice"
                    v-model="form.invoice_id"
                    :options="options.invoices"
                  ></b-form-select>
                </b-form-group>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3 required"
                      id="type-group"
                      label="Type"
                      label-for="type"
                    >
                      <b-form-select
                        :disabled="loading.form"
                        class="w-100"
                        id="type"
                        v-model="type"
                        :options="options.types"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="required"
                      id="amount-group"
                      label="Amount"
                      label-for="amount"
                    >
                      <b-form-input
                        :disabled="loading.form"
                        class="mb-3"
                        id="amount"
                        v-model="amount"
                        type="number"
                        placeholder="0.00"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  class="required"
                  id="description-group"
                  label="Narration or Description"
                  label-for="description"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="description"
                    v-model="form.description"
                    type="text"
                    placeholder="Enter the transaction description."
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    :variant="type == 'Cr' ? 'success' : 'danger'"
                    >Record {{ type == "Cr" ? "Credit" : "Debit" }}</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountService from "@/services/AccountService";
import InvoiceService from "@/services/InvoiceService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "RecordTransactionView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      type: "Cr",
      amount: "",
      form: {
        account_id: "",
        invoice_id: "",
        amount: "",
        description: "",
      },
      options: {
        types: [
          { text: "Credit", value: "Cr" },
          { text: "Debit", value: "Dr" },
        ],
        accounts: [{ value: "", text: "Select Account" }],
        invoices: [{ value: "", text: "Select Invoice - Optional " }],
      },
      success: "",
      errors: [],
    };
  },

  mounted() {
    this.getAccounts();
    this.getInvoices();
  },

  methods: {
    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Format amount.
      if (this.type == "Dr") {
        this.form.amount = this.amount * -1;
      } else {
        this.form.amount = this.amount;
      }

      // Submit form.
      AccountService.recordTransaction(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.invoice_id = "";
      this.form.account_id = "";
      this.amount = "";
      this.form.description = "";
    },

    async getAccounts() {
      let response = await AccountService.getAccounts(1, 12);
      this.options.accounts = [
        this.options.accounts[0],
        ...response.data.accounts.data.map((item) => {
          return {
            text: `${item.name} - GHS ${item.balance}`,
            value: `${item.id}`,
          };
        }),
      ];
    },

    async getInvoices() {
      let response = await InvoiceService.getInvoices(
        1,
        10000,
        "created_at",
        "asc",
        "",
        "pending"
      );
      this.options.invoices = [
        this.options.invoices[0],
        ...response.data.invoices.data.map((item) => {
          return {
            text:
              `#${item.id} - GHS ${item.total} - ` +
              (item.type == "client" ? item.client.name : item.staff.name),
            value: `${item.id}`,
          };
        }),
      ];
    },
  },
};
</script>

