<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>Add a New Staff</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <b-form-group
                  id="name-group"
                  label="Name"
                  label-for="name"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="name"
                    v-model="form.name"
                    type="text"
                    placeholder="Enter the staff's full name."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="code-group"
                  label="Code"
                  label-for="code"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="code"
                    v-model="form.code"
                    type="text"
                    placeholder="Enter the staff's code."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="job-title-group"
                  label="Job Title"
                  label-for="job-title"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="job-title"
                    v-model="form.job_title"
                    type="text"
                    placeholder="Enter the staff's job title."
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3 required"
                      id="role-group"
                      label="Role"
                      label-for="role"
                    >
                      <b-form-select
                        :disabled="loading.form"
                        class="w-100"
                        id="role"
                        v-model="form.role_code"
                        :options="options.roles"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3 required"
                      id="hourly-rate-group"
                      label="Hourly Rate"
                      label-for="hourly_rate"
                    >
                      <b-form-input
                        :disabled="loading.form"
                        class="mb-3"
                        id="hourly_rate"
                        v-model="form.hourly_rate"
                        type="number"
                        placeholder="0.00"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  id="phone-group"
                  label="Phone"
                  label-for="phone"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="phone"
                    v-model="form.phone"
                    type="text"
                    placeholder="Phone number of Staff."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="email-group"
                  label="Email"
                  label-for="email"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="email"
                    v-model="form.email"
                    type="text"
                    placeholder="NG Email of Staff."
                    email
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  class="required"
                  id="password-group"
                  label="Password"
                  label-for="password"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="password"
                    v-model="form.password"
                    type="password"
                    placeholder="***********"
                    required
                  ></b-form-input>
                </b-form-group>

                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Onboard Staff</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaffService from "@/services/StaffService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddStaffView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        name: "",
        code: "",
        job_title: "",
        hourly_rate: "",
        email: "",
        phone: "",
        password: "",
        role_code: "",
      },
      options: {
        roles: [{ value: "", text: "Select Role" }],
      },
      success: "",
      errors: [],
    };
  },

  mounted() {
    this.getRoles();
  },

  methods: {
    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      StaffService.addStaff(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.name = "";
      this.form.code = "";
      this.form.job_title = "";
      this.form.hourly_rate = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.password = "";
      this.form.role_code = "";
    },

    async getRoles() {
      let response = await StaffService.getRoles(1, 10, "name", "desc");
      this.options.roles = [
        this.options.roles[0],
        ...response.data.staff_roles.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },
  },
};
</script>

