<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="row mb-2">
            <div class="col-md-12 text-start">
              <h6>
                <span v-if="ticket.is_private == 1" class="badge bg-dark"
                  >INTERNAL - Only Visible to Nusoft Group Staff</span
                >
                <span v-else class="badge bg-success"
                  >EXTERNAL - Visible to Internal and External Users.</span
                >
              </h6>
              <h4>
                T{{
                  ticket.id + " - " + ticket.client.name + " - " + ticket.title
                }}
                {{ ticket.site_id != null ? " - " + ticket.site.url : "" }}
              </h4>
            </div>
            <div class="col-md-12 text-start">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div>
                    <b-alert
                      dismissible
                      class="mb-1"
                      variant="success"
                      :show="
                        success !== '' &&
                        [
                          'status_update',
                          'category_update',
                          'add_quote',
                          'update_quote',
                          'approve_quote',
                          'assign_staff',
                          'allocate_time',
                        ].includes(update.type_code)
                      "
                      >{{ success }}</b-alert
                    >
                    <b-alert
                      dismissible
                      class="mb-1"
                      variant="danger"
                      :show="
                        errors.length > 0 &&
                        [
                          'status_update',
                          'category_update',
                          'add_quote',
                          'update_quote',
                          'approve_quote',
                          'assign_staff',
                          'allocate_time',
                        ].includes(update.type_code)
                      "
                    >
                      <ul>
                        <li v-for="error in errors" :key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </b-alert>
                  </div>
                  <div class="ng-card">
                    <b-form-group
                      class="mb-3"
                      id="status-group"
                      label="Status"
                      label-for="status"
                    >
                      <b-form-select
                        @change="statusChanged"
                        :disabled="loading.form"
                        class="w-100 fw-bold"
                        id="status"
                        v-model="update.status_code"
                        :options="options.statuses"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="mb-2"
                      id="category-group"
                      label="Category"
                      label-for="category"
                    >
                      <b-form-select
                        :disabled="
                          this.loading.form ||
                          ['completed', 'cancelled'].includes(
                            this.ticket.status_code
                          )
                        "
                        @change="categoryChanged"
                        class="w-100 fw-bold"
                        id="category"
                        v-model="update.category_code"
                        :options="options.categories"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div
                    class="ng-card mt-2"
                    v-if="ticket.category_code != 'bug_report'"
                    style="font-size: 0.9rem"
                  >
                    <div
                      v-if="!ticket.time_budget && !ticket.is_budget_approved"
                    >
                      <span class="text-danger"
                        >Quote <b>not set</b> or <b>approved</b>.</span
                      >
                      <div
                        v-if="
                          !['completed', 'cancelled'].includes(
                            this.ticket.status_code
                          )
                        "
                      >
                        <a href="" @click.prevent="showModal('add_quote')"
                          >Add Quote</a
                        >
                      </div>
                    </div>
                    <div
                      v-else-if="
                        ticket.time_budget && !ticket.is_budget_approved
                      "
                    >
                      <div>
                        <span class="text-danger"
                          ><b
                            ><span class="currency_code text-danger">GH₵ </span
                            >{{
                              numberWithCommas(
                                ticket.hourly_rate * ticket.time_budget
                              )
                            }}
                          </b>
                          - Pending approval.</span
                        >
                      </div>
                      <div>
                        <b>{{ ticket.time_budget }}</b> hour{{
                          ticket.time_budget > 1 ? "s" : ""
                        }}
                        at
                        <b
                          ><span class="currency_code">GH₵ </span
                          >{{ ticket.hourly_rate }}
                        </b>
                        / h.
                        <a
                          v-if="
                            !['completed', 'cancelled'].includes(
                              this.ticket.status_code
                            )
                          "
                          href=""
                          @click.prevent="showModal('update_quote')"
                          >Edit</a
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div>
                        <span class="text-success"
                          ><b
                            ><span class="currency_code text-success">GH₵ </span
                            >{{
                              numberWithCommas(
                                ticket.hourly_rate * ticket.time_budget
                              )
                            }}
                          </b>
                          - Approved.</span
                        >
                      </div>
                      <div>
                        <b>{{ ticket.time_budget }}</b> hour{{
                          ticket.time_budget > 1 ? "s" : ""
                        }}
                        at
                        <b
                          ><span class="currency_code">GH₵ </span
                          >{{ ticket.hourly_rate }}
                        </b>
                        / h.
                        <a
                          v-if="
                            !['completed', 'cancelled'].includes(
                              this.ticket.status_code
                            )
                          "
                          href=""
                          @click.prevent="showModal('update_quote')"
                          >Edit</a
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      ticket.assigned_users.length > 0 ||
                      !['completed', 'cancelled'].includes(
                        this.ticket.status_code
                      )
                    "
                    class="ng-card mt-2"
                    style="font-size: 0.9rem"
                  >
                    <div v-if="ticket.assigned_users.length > 0">
                      <b>Assigned:</b>
                      <ul>
                        <li
                          v-for="user in ticket.assigned_users"
                          :key="user.user_id"
                        >
                          {{ user.user.name }}
                        </li>
                      </ul>
                    </div>
                    <div class="mt-2 text-start">
                      <a
                        v-if="
                          !['completed', 'cancelled'].includes(
                            this.ticket.status_code
                          )
                        "
                        href=""
                        @click.prevent="showModal('assign_staff')"
                        >Assign Staff</a
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      ticket.allocated_time.length > 0 ||
                      !['completed', 'cancelled'].includes(
                        this.ticket.status_code
                      )
                    "
                    class="ng-card mt-2"
                    style="font-size: 0.9rem"
                  >
                    <div v-if="ticket.allocated_time.length > 0">
                      <b>Allocated:</b>
                      <ul>
                        <li
                          v-for="time in ticket.allocated_time"
                          :key="time.id"
                        >
                          <b>{{ time.time }} h</b> - {{ time.user.name }}
                        </li>
                      </ul>
                    </div>
                    <div class="mt-2 text-start">
                      <a
                        v-if="
                          !['completed', 'cancelled'].includes(
                            this.ticket.status_code
                          )
                        "
                        href=""
                        @click.prevent="showModal('allocate_time')"
                        >Allocate Time</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-8 mt-2">
                  <div>
                    <div class="ng-card">
                      <b-alert
                        class="mb-1"
                        variant="success"
                        :show="success !== '' && update.type_code == 'message'"
                        >{{ success }}</b-alert
                      >
                      <b-alert
                        class="mb-1"
                        variant="danger"
                        :show="
                          errors.length > 0 && update.type_code == 'message'
                        "
                      >
                        <ul>
                          <li v-for="error in errors" :key="error">
                            {{ error }}
                          </li>
                        </ul>
                      </b-alert>
                      <b-form @submit.prevent="onSubmit('message')">
                        <b-form-group id="update-group" label-for="update">
                          <editor
                            :disabled="
                              this.loading.form ||
                              ['completed', 'cancelled'].includes(
                                this.ticket.status_code
                              )
                            "
                            id="update"
                            v-model="update.message"
                            api-key="4rq3ibpfp8f38sdodmtvtrh8oczqt541wz9our5ey3o9xjlj"
                            :init="{
                              placeholder: 'Enter your update here.',
                              height: 200,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat ',
                            }"
                          />
                        </b-form-group>
                        <b-form-file
                          class="form-control mb-2 mt-2"
                          v-model="update.files"
                          placeholder="Attach Files"
                          multiple
                          plain
                          ref="attachments"
                          :disabled="
                            this.loading.form ||
                            ['completed', 'cancelled'].includes(
                              this.ticket.status_code
                            )
                          "
                        ></b-form-file>

                        <div class="text-end mt-1">
                          <b-button
                            size="sm"
                            :disabled="
                              this.loading.form ||
                              ['completed', 'cancelled'].includes(
                                this.ticket.status_code
                              )
                            "
                            @click.prevent="updateTicketPrivately"
                            variant="outline-dark"
                            class="me-1"
                            >Private Update</b-button
                          >
                          <b-button
                            size="sm"
                            :disabled="
                              this.loading.form ||
                              ['completed', 'cancelled'].includes(
                                this.ticket.status_code
                              )
                            "
                            type="submit"
                            variant="primary"
                            >Update Ticket</b-button
                          >
                        </div>
                      </b-form>
                    </div>
                  </div>
                  <div>
                    <div
                      v-for="(update, index) in ticket.updates"
                      :key="update.id"
                      class="ng-card mt-3"
                    >
                      <div class="update-user-name mt-2">
                        {{ update.user.name + " - " }}
                        {{
                          update.user.type_code == "staff"
                            ? "Nusoft Group"
                            : ticket.client.name
                        }}
                      </div>
                      <div class="update-date">
                        {{
                          moment(update.created_at).format(
                            "dddd MMMM Do YYYY, h:mm a"
                          )
                        }}
                      </div>
                      <p
                        :class="
                          [
                            'message',
                            'add_quote',
                            'update_quote',
                            'approve_quote',
                            'assign_staff',
                            'allocate_time',
                          ].includes(update.type_code)
                            ? ''
                            : 'text-muted mb-2 font-italic'
                        "
                        :style="
                          [
                            'message',
                            'add_quote',
                            'update_quote',
                            'approve_quote',
                            'assign_staff',
                            'allocate_time',
                          ].includes(update.type_code)
                            ? ''
                            : 'font-style: italic'
                        "
                      >
                        <span v-html="update.message"></span>
                      </p>
                      <div v-if="update.attachments.length > 0" class="mb-2">
                        <span class="update-date">Attachments</span>

                        <div
                          style="font-size: 0.7rem; line-height: 1.3"
                          v-for="attachment in update.attachments"
                          :key="attachment.id"
                        >
                          <a
                            :href="attachment.file.storage_link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span
                              ><b-icon icon="paperclip" variant="dark"></b-icon>
                              {{ attachment.file.name }}</span
                            >
                          </a>
                        </div>
                      </div>
                      <div
                        class="text-end"
                        v-if="
                          ['add_quote', 'update_quote'].includes(
                            update.type_code
                          ) &&
                          index == 0 &&
                          ticket.is_budget_approved == 0 &&
                          !['completed', 'cancelled'].includes(
                            ticket.status_code
                          )
                        "
                      >
                        <b-button
                          @click.prevent="showModal('approve_quote')"
                          size="sm"
                          :disabled="loading.form"
                          variant="success"
                          >Approve Quote</b-button
                        >
                      </div>
                      <div>
                        <span
                          v-if="update.is_private == 1"
                          class="badge bg-dark"
                          >Private</span
                        >
                        <span v-else class="badge bg-success fw-bold"
                          >Public</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="ticket-modal"
      :size="
        update.type_code == 'add_quote' || update.type_code == 'update_quote'
          ? 'md'
          : 'sm'
      "
      no-close-on-backdrop
    >
      <template #modal-header>
        <h5>{{ modal.title }}</h5>
      </template>
      <div
        v-if="
          update.type_code == 'add_quote' || update.type_code == 'update_quote'
        "
      >
        <div class="row mt-2">
          <div class="col-md-4">
            <b-form-group
              id="time-budget-group"
              label="Hours"
              label-for="time_budget"
            >
              <b-form-input
                :disabled="loading.form"
                id="time_budget"
                v-model="update.time_budget"
                type="number"
                placeholder="0"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="rate-group"
              label="Rate per Hour."
              label-for="rate"
            >
              <b-form-input
                :disabled="loading.form"
                id="rate"
                v-model="update.hourly_rate"
                type="number"
                placeholder="0"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group id="cost-group" label="Cost" label-for="cost">
              <b-form-input
                :disabled="true"
                id="cost"
                v-model="quote"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
      <div v-if="update.type_code == 'assign_staff'">
        <b-form-group
          class="required"
          id="assigned-staff-group"
          label="Staff"
          label-for="assigned_staff"
        >
          <multiselect
            :max-height="150"
            :limit="3"
            v-model="update.assigned_staff"
            :options="options.staff"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder="Select Staff to Assign"
            select-label="Select"
            deselect-label="Remove"
            label="text"
            track-by="value"
            id="assigned_staff"
          >
            <template #selection="{ values, isOpen }">
              <span
                class="multiselect__single"
                v-if="values.length"
                v-show="!isOpen"
                >{{ values.length }} staff selected</span
              >
            </template>
          </multiselect>
        </b-form-group>
      </div>
      <div v-if="update.type_code == 'allocate_time'">
        <b-form-group
          class="mb-2 required"
          id="staff-group"
          label="Assigned Staff"
          label-for="staff"
        >
          <b-form-select
            :disabled="loading.form"
            class="w-100"
            id="staff"
            v-model="update.allocate.staff_id"
            :options="options.assignedStaff"
            required
          >
            <b-form-select-option :value="null"
              >Select Staff</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          id="hours-group"
          label="Hours"
          label-for="hours"
          class="required"
        >
          <b-form-input
            :disabled="loading.form"
            id="hours"
            v-model="update.allocate.time"
            type="number"
            step="0.5"
            placeholder="0"
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <p class="mb-0 mt-2"><span v-html="this.modal.body"></span></p>
      </div>

      <template #modal-footer>
        <div class="text-end">
          <b-button
            class="me-1"
            size="sm"
            :disabled="loading.form"
            variant="outline-danger"
            @click.prevent="closeModal(update.type_code)"
            >Cancel</b-button
          >
          <b-button
            size="sm"
            :disabled="loading.form"
            @click="modal.action"
            variant="success"
            >{{ modal.confirm_button_text }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import StaffService from "@/services/StaffService";
import TicketService from "@/services/TicketService";
import { formatErrors, numberWithCommas } from "@/utils/helpers";
import Editor from "@tinymce/tinymce-vue";
import Multiselect from "vue-multiselect";

export default {
  name: "ViewTicketView",
  components: {
    CustomLoader,
    editor: Editor,
    Multiselect,
  },
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      ticket: {},
      update: {
        type_code: null,
        category_code: null,
        status_code: null,
        message: "",
        quote: "",
        is_private: 0,
        files: [],
        hourly_rate: "",
        time_budget: "",
        assigned_staff: [],
        allocate: {
          time: "",
          staff_id: null,
        },
      },
      options: {
        categories: [{ value: null, text: "Not Set" }],
        statuses: [{ value: null, text: "Not Set" }],
        staff: [],
        assignedStaff: [],
      },
      success: "",
      errors: [],
      modal: {
        title: "",
        body: "",
        action: "",
        confirm_button_text: "",
        headerBgVariant: "primary",
      },
    };
  },

  async mounted() {
    await this.getTicket(this.$route.params.id);
    this.getCategories();
    this.getStatuses();
    this.getStaff();
    document.title = `Staff - T${this.ticket.id} - ${this.ticket.title}`;
    this.loading.page = false;
  },

  computed: {
    quote() {
      if (this.update.time_budget && this.update.hourly_rate) {
        return numberWithCommas(
          this.update.time_budget * this.update.hourly_rate
        );
      }

      return numberWithCommas(0);
    },
  },

  methods: {
    async getStaff() {
      this.loading.page = true;

      let staffResponse = await StaffService.getStaff(
        1,
        100,
        "name",
        "asc",
        null,
        null,
        null
      );

      this.options.staff = staffResponse.data.staff.data.map((item) => {
        return {
          text: `${item.name}`,
          value: `${item.id}`,
        };
      });
    },

    async getTicket(ticketId) {
      this.loading.page = true;
      let response = await TicketService.getTicket(ticketId);
      this.ticket = response.data.ticket;
      this.update.status_code = this.ticket.status_code;
      this.update.category_code = this.ticket.category_code;
      this.update.time_budget = this.ticket.time_budget;
      this.update.hourly_rate =
        this.ticket.hourly_rate ?? this.ticket.client.hourly_rate;
      this.loading.page = false;

      // Get assigned users
      this.options.assignedStaff = this.ticket.assigned_users.map((item) => {
        return {
          text: `${item.user.name}`,
          value: `${item.user.id}`,
        };
      });
    },

    async getCategories() {
      let response = await TicketService.getCategories();
      this.options.categories = [
        this.options.categories[0],
        ...response.data.ticket_categories.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },

    async getStatuses() {
      let response = await TicketService.getStatuses();
      this.options.statuses = [
        this.options.statuses[0],
        ...response.data.ticket_statuses.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },

    updateTicketPrivately() {
      this.update.is_private = 1;
      this.onSubmit("message");
    },

    async onSubmit(type_code) {
      this.update.type_code = type_code;

      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Build payload
      let payload;
      payload = new FormData();
      payload.append("type_code", this.update.type_code);

      switch (this.update.type_code) {
        case "approve_quote":
          // No payload.
          break;
        case "add_quote":
        case "update_quote":
          payload.append("time_budget", this.update.time_budget);
          payload.append("hourly_rate", this.update.hourly_rate);
          break;
        case "assign_staff":
          for (let i = 0; i < this.update.assigned_staff.length; i++) {
            let staff = this.update.assigned_staff[i];
            payload.append("assigned_staff[" + i + "]", staff.value);
          }
          break;
        case "allocate_time":
          payload.append("staff_id", this.update.allocate.staff_id);
          payload.append("time", this.update.allocate.time);
          break;

        default: // Message, Status and Category updates.
          payload.append("category_code", this.update.category_code);
          payload.append("status_code", this.update.status_code);
          payload.append("message", this.update.message);
          payload.append("is_private", this.update.is_private);
          if (this.update.files) {
            for (let i = 0; i < this.update.files.length; i++) {
              let file = this.$refs["attachments"].files[i];
              payload.append("files[" + i + "]", file);
            }
          }
          break;
      }

      // Submit form.
      TicketService.updateTicket(this.ticket.id, payload)
        .then((response) => {
          this.success = response.data.message;
          this.ticket = response.data.ticket;

          // Re-populate values required for update.
          this.update.status_code = this.ticket.status_code;
          this.update.category_code = this.ticket.category_code;

          this.update.time_budget = this.ticket.time_budget;
          this.update.hourly_rate =
            this.ticket.hourly_rate ?? this.ticket.client.hourly_rate;

          // Get assigned users
          this.options.assignedStaff = this.ticket.assigned_users.map(
            (item) => {
              return {
                text: `${item.user.name}`,
                value: `${item.user.id}`,
              };
            }
          );

          // Reset form
          this.update.assigned_staff = [];

          this.update.allocate.staff_id = null;
          this.update.allocate.time = "";

          this.update.is_private = 0;
          this.update.message = "";
          this.update.files = [];
          this.$refs["attachments"].files = [];
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
          this.$refs["ticket-modal"].hide();
        });
    },

    statusChanged() {
      let oldStatus = this.options.statuses.filter((status) => {
        return status.value == this.ticket.status_code;
      });
      let newStatus = this.options.statuses.filter((status) => {
        return status.value == this.update.status_code;
      });
      let body = `The ticket status will be updated from <b>${oldStatus[0].text}</b> to <b>${newStatus[0].text}</b>. ${this.ticket.client.name} and assigned Nusoft Group staff members will be notified.`;
      this.showModal("status_update", body);
    },

    categoryChanged() {
      let oldCategory = this.options.categories.filter((category) => {
        return category.value == this.ticket.category_code;
      });
      let newCategory = this.options.categories.filter((category) => {
        return category.value == this.update.category_code;
      });
      let body = `The ticket category will be updated from <b>${oldCategory[0].text}</b> to <b>${newCategory[0].text}</b>. ${this.ticket.client.name} and assigned Nusoft Group staff members will be notified.`;
      this.showModal("category_update", body);
    },

    showModal(type, body = "") {
      this.modal.body = body;
      this.update.type_code = type;

      switch (type) {
        case "status_update":
          this.modal.title = "Update Status ?";
          this.modal.action = () => {
            this.onSubmit("status_update");
          };
          this.modal.confirm_button_text = "Update Status";
          break;
        case "category_update":
          this.modal.title = "Update Category ?";
          this.modal.action = () => {
            this.onSubmit("category_update");
          };
          this.modal.confirm_button_text = "Update Category";

          break;
        case "add_quote":
          this.modal.title = "Add Quote";
          this.modal.body =
            "Quote will be sent to " +
            this.ticket.client.name +
            " for approval. Please ensure you have <b>double checked the estimate before submitting</b>.";
          this.modal.action = () => {
            this.onSubmit("add_quote");
          };
          this.modal.confirm_button_text = "Add Quote";
          break;
        case "update_quote":
          this.modal.title = "Update Quote";
          this.modal.body =
            "Quote will be sent to " +
            this.ticket.client.name +
            " for approval. Please ensure you have <b>double checked the estimate before submitting</b>.";
          this.modal.action = () => {
            this.onSubmit("update_quote");
          };
          this.modal.confirm_button_text = "Update Quote";
          break;
        case "approve_quote":
          this.modal.title = "Approve Quote";
          this.modal.body =
            "Quote will be approved on behalf of " +
            this.ticket.client.name +
            ". Please ensure you have <b>express approval from the client</b>.";
          this.modal.action = () => {
            this.onSubmit("approve_quote");
          };
          this.modal.confirm_button_text = "Approve Quote";
          break;
        case "assign_staff":
          this.modal.title = "Assign Staff";
          this.modal.body =
            "Assigned staff will receive notification on updates to this ticket, and can be allocated time to work on it.";
          this.modal.action = () => {
            this.onSubmit("assign_staff");
          };
          this.modal.confirm_button_text = "Assign Staff";
          break;
        case "allocate_time":
          this.modal.title = "Allocate Time";
          this.modal.body =
            "Allocating time to staff allows them track time while working on this ticket. Staff will be paid for allocated time once this ticket is completed.";
          this.modal.action = () => {
            this.onSubmit("allocate_time");
          };
          this.modal.confirm_button_text = "Allocate Time";
          break;

        default:
          break;
      }

      this.$refs["ticket-modal"].show();
    },

    closeModal(type) {
      switch (type) {
        case "status_update":
          this.update.status_code = this.ticket.status_code;
          this.$refs["ticket-modal"].hide();
          break;
        case "category_update":
          this.update.category_code = this.ticket.category_code;
          this.$refs["ticket-modal"].hide();
          break;
        case "add_quote":
        case "update_quote":
        case "approve_quote":
          this.$refs["ticket-modal"].hide();
          break;
        case "assign_staff":
          this.update.assigned_staff = [];
          this.$refs["ticket-modal"].hide();
          break;
        case "allocate_time":
          this.update.allocate.staff_id = "";
          this.update.allocate.time = "";
          this.$refs["ticket-modal"].hide();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__content {
  padding-left: 0rem !important;
}
</style>
