<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>Add a New Setting</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <b-form-group
                  class="required"
                  id="name-group"
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="name"
                    v-model="form.name"
                    type="text"
                    placeholder="Enter the setting's name."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="description-group"
                  label="Description"
                  label-for="description"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="description"
                    v-model="form.description"
                    type="text"
                    placeholder="Describe the setting."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="value-group"
                  label="Value"
                  label-for="value"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="value"
                    v-model="form.value"
                    type="text"
                    placeholder="Enter the setting's value."
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Save Setting</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingService from "@/services/SettingService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddSettingView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        name: "",
        description: "",
        value: "",
      },
      success: "",
      errors: [],
    };
  },

  methods: {
    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      SettingService.addSetting(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.name = "";
      this.form.description = "";
      this.form.value = "";
    },
  },
};
</script>

