<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>{{ client.name }} - Add User</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <b-form-group
                  id="name-group"
                  label="Name"
                  label-for="name"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="name"
                    v-model="form.name"
                    type="text"
                    placeholder="Enter the user's full name."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="phone-group"
                  label="Phone"
                  label-for="phone"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="phone"
                    v-model="form.phone"
                    type="text"
                    placeholder="Phone number of user."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="email-group"
                  label="Email"
                  label-for="email"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="email"
                    v-model="form.email"
                    type="text"
                    placeholder="Email of user."
                    email
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  class="required"
                  id="password-group"
                  label="Password"
                  label-for="password"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="password"
                    v-model="form.password"
                    type="password"
                    placeholder="***********"
                    required
                  ></b-form-input>
                </b-form-group>

                <div class="text-end">
                  <a :href="`/clients/view/${this.$route.params.clientId}`">
                    <b-button size="sm" variant="dark"
                      >Back to Client</b-button
                    ></a
                  >
                  &nbsp;
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Onboard User</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { formatErrors } from "@/utils/helpers";
import ClientService from "@/services/ClientService";

export default {
  name: "AddClientUserView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
      },
      client: {},
      success: "",
      errors: [],
    };
  },

  mounted() {
    this.getClient();
  },

  methods: {
    async getClient() {
      let response = await ClientService.getClient(this.$route.params.clientId);
      this.client = response.data.client;
    },
    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      ClientService.addClientUser(this.$route.params.clientId, this.form)
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.password = "";
    },
  },
};
</script>
  
  