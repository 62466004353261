<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>
                P{{ project.id }} - {{ project.client.name }} -
                {{ project.name }}
              </h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit">
                <b-form-group
                  class="mb-3 mt-3 required"
                  id="client-group"
                  label="Client"
                  label-for="client"
                >
                  <b-form-select
                    :disabled="true"
                    class="w-100"
                    id="client"
                    v-model="form.client_code"
                    :options="options.clients"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="name-group"
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="name"
                    v-model="form.name"
                    type="text"
                    placeholder="Enter the project name."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="description-group"
                  label="Description"
                  label-for="description"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="description"
                    v-model="form.description"
                    type="text"
                    placeholder="Link to Google Sheet on Project."
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3 required"
                      id="hourly-rate-group"
                      label="Rate per Hour"
                      label-for="hourly_rate"
                    >
                      <b-form-input
                        :disabled="loading.form"
                        class="mb-3"
                        id="hourly_rate"
                        v-model="form.hourly_rate"
                        type="number"
                        placeholder="0.00"
                        step="0.01"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="required"
                      id="time-budget-group"
                      label="Total Hours"
                      label-for="time_budget"
                    >
                      <b-form-input
                        :disabled="loading.form"
                        class="mb-3"
                        id="time_budget"
                        v-model="form.time_budget"
                        type="number"
                        placeholder="0"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  id="description-group"
                  label="Estimated Cost of Project"
                  label-for="estimated_cost_of_project"
                >
                  <b-form-input
                    :disabled="true"
                    class="mb-3 text-success fw-bold"
                    id="estimated_cost_of_project"
                    v-model="estimated_project_cost"
                    type="text"
                    placeholder="0.00"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="mb-3 mt-3 required"
                  id="status-group"
                  label="Status"
                  label-for="status"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="status"
                    v-model="form.status_code"
                    :options="options.statuses"
                    required
                  ></b-form-select>
                </b-form-group>
                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Update Project</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader.vue";
import ClientService from "@/services/ClientService";
import ProjectService from "@/services/ProjectService";
import { formatErrors, numberWithCommas } from "@/utils/helpers";

export default {
  name: "ViewProjectView",
  components: {
    CustomLoader,
  },
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        client_code: "",
        name: "",
        description: "",
        hourly_rate: "",
        time_budget: "",
        status_code: "",
      },
      options: {
        clients: [{ value: "", text: "Select Client" }],
        statuses: [],
      },
      success: "",
      errors: [],
    };
  },

  async mounted() {
    await this.getClients();
    await this.getProject();
    await this.getStatuses();
    document.title = `Staff - P${this.project.id} - ${this.project.client.name} - ${this.project.name}`;
    this.loading.page = false;
  },

  computed: {
    estimated_project_cost() {
      let estimated_cost = 0;

      if (this.form.hourly_rate && this.form.time_budget) {
        estimated_cost = this.form.hourly_rate * this.form.time_budget;
      }

      return "GHS " + numberWithCommas(estimated_cost);
    },
  },

  methods: {
    async getProject() {
      this.loading.page = true;
      let response = await ProjectService.getProject(this.$route.params.id);
      this.project = response.data.project;
      this.loading.page = false;

      // Set data
      this.form.client_code = this.project.client_code;
      this.form.name = this.project.name;
      this.form.description = this.project.description;
      this.form.hourly_rate = this.project.hourly_rate;
      this.form.time_budget = this.project.time_budget;
      this.form.status_code = this.project.status_code;
    },

    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      ProjectService.updateProject(this.$route.params.id, this.form)
        .then((response) => {
          this.success = response.data.message;
          this.project = response.data.project;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    async getClients() {
      let response = await ClientService.getClients(1, 1000000, "name", "desc");
      this.options.clients = [
        this.options.clients[0],
        ...response.data.clients.data.map((item) => {
          return {
            text: `${item.name} ${
              item.hourly_rate != null
                ? " - GHS " + item.hourly_rate + " / hour"
                : ""
            }`,
            value: `${item.code}`,
          };
        }),
      ];
    },

    async getStatuses() {
      let response = await ProjectService.getStatuses();
      this.options.statuses = [
        ...response.data.project_statuses.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },
  },
};
</script>

