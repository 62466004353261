<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12 text-start">
              <h3 class="mb-3">Time Tracking</h3>
            </div>
          </div>
          <div v-if="timeEntries.length === 0 && !loading.page">
            <CenterMessage message="No time entries found."></CenterMessage>
          </div>
          <div v-else class="text-start">
            <div v-for="(timeEntry, index) in timeEntries" :key="timeEntry.id">
              <div
                v-if="
                  index == 0 ||
                  moment(timeEntries[index].created_at).format(
                    'dddd MMMM Do YYYY'
                  ) !=
                    moment(timeEntries[index - 1].created_at).format(
                      'dddd MMMM Do YYYY'
                    )
                "
              >
                <div :class="'mb-2 ' + (index != 0 ? 'mt-4' : '')">
                  {{
                    moment(timeEntries[index].start_time).format(
                      "dddd MMMM Do, YYYY."
                    )
                  }}
                </div>
              </div>
              <table class="table">
                <tbody>
                  <tr class="text-start">
                    <th scope="row">{{ timeEntries[index].id }}</th>
                    <td class="text-nowrap">
                      {{ timeEntries[index].staff.name }}
                    </td>
                    <td style="width: 500px">
                      <a
                        target="_blank"
                        :href="'/tickets/view/' + timeEntries[index].ticket.id"
                        >{{
                          "T" +
                          timeEntries[index].ticket.id +
                          " - " +
                          timeEntries[index].ticket.client.name +
                          " - " +
                          timeEntries[index].ticket.title
                        }}</a
                      >
                    </td>
                    <td class="text-nowrap">
                      {{
                        moment(timeEntries[index].start_time).format("h:mm a")
                      }}
                    </td>
                    <td class="text-nowrap">
                      <span
                        v-if="!timeEntries[index].end_time"
                        class="text-muted"
                        >Ongoing</span
                      >
                      <span v-else>
                        {{
                          moment(timeEntries[index].end_time).format("h:mm a")
                        }}
                      </span>
                    </td>
                    <td class="text-nowrap">
                      {{ convertSecondsToTime(timeEntries[index].duration) }}
                    </td>
                    <td class="text-end">
                      <a :href="'/time-entries/view/' + timeEntries[index].id"
                        >Edit</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Staff</th>
                  <th scope="col">Ticket</th>
                  <th scope="col">Start</th>
                  <th scope="col">End</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="timeEntry in timeEntries" :key="timeEntry.id">
                  <th scope="row">{{ timeEntry.id }}</th>
                  <td>{{ timeEntry.staff.name }}</td>
                  <td>
                    <a
                      target="_blank"
                      :href="'/tickets/view/' + timeEntry.ticket.id"
                      >{{
                        "T" +
                        timeEntry.ticket.id +
                        " - " +
                        timeEntry.ticket.client.name +
                        " - " +
                        timeEntry.ticket.title
                      }}</a
                    >
                  </td>
                  <td>
                    {{ new Date(timeEntry.start_time).toLocaleString("nl-NL") }}
                  </td>
                  <td>
                    {{ new Date(timeEntry.end_time).toLocaleString("nl-NL") }}
                  </td>
                  <td>{{ convertSecondsToTime(timeEntry.duration) }}</td>
                  <td class="text-end">
                    <a :href="'/time-entries/view/' + timeEntry.id">Edit</a>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import TimeTrackingService from "@/services/TimeTrackingService";
import CenterMessage from "@/components/CenterMessage.vue";

export default {
  name: "ListTimeEntriesView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "staff",
        "ticket",
        "start_time",
        "end_time",
        "duration",
        "action",
      ],
      timeEntries: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
      sort: {
        column: "created_at",
        direction: "desc",
      },
    };
  },

  async mounted() {
    await this.getTrackingEntries();
    this.loading.page = false;
  },

  methods: {
    async getTrackingEntries(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await TimeTrackingService.getTrackingEntries(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.sort.column,
        this.sort.direction
      );

      this.timeEntries = response.data.time_entries.data;
      this.pagination.totalRows = response.data.time_entries.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.time_entries.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getTrackingEntries();
      }
    },

    convertSecondsToTime(durationInSeconds) {
      const tracked_time = new Date(1970, 0, 1, 0, 0, durationInSeconds);
      const hours = tracked_time.getHours();
      const minutes = tracked_time.getMinutes();
      const seconds = tracked_time.getSeconds();

      // Format the string with leading zeroes
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
  },
};
</script>

<style>
.table {
  margin-bottom: 0;
}
</style>