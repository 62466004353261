import { ApiService } from "./APIService";

export default {
    getSettings(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null
    ) {
        // Generate url
        let url = 'api/staff/settings?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        return ApiService.get(url);
    },

    addSetting(payload) {
        return ApiService.post("api/staff/settings/add", payload);
    },

    updateSetting(setting_id, payload) {
        return ApiService.post("/api/staff/settings/" + setting_id + "/update", payload);
    },

    getSetting(setting_id) {
        return ApiService.get("api/staff/settings/" + setting_id);
    },
}