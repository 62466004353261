import UserService from "@/services/UserService";

export default {
    namespaced: true,

    state: {
        user: null,
        userToken: null,
        caretakerToken: null,
    },

    actions: {
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserService.generateToken(payload)
                    .then(async ({data}) => {
                        // Set user token
                        commit('SET_USER_TOKEN', data.token);

                        commit('SET_AUTHENTICATED_USER', data.user);

                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        destroyCaretakerToken({commit}){
            commit('SET_CARETAKER_TOKEN', null);
        },

        logout({ commit }){
            commit('SET_USER_TOKEN', null);
            commit('SET_AUTHENTICATED_USER', null);
        }

    },

    mutations: {
        SET_AUTHENTICATED_USER(state, data) {
            state.user = data;
        },

        SET_USER_TOKEN(state, data) {
            state.userToken = data;
        },

        SET_CARETAKER_TOKEN(state, data) {
            state.caretakerToken = data;
        },
    },

    getters: {
        user: (state) => state.user,
        userToken: (state) => state.userToken,
        caretakerToken: (state) => state.caretakerToken,
    },
};
