<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="row mb-2">
            <div class="col-md-6 text-start">
              <h3>Sites</h3>
            </div>
            <div class="col-md-6 text-end">
              <a href="/sites/add"
                ><b-button size="sm" class="fw-bold" variant="primary">
                  New Site
                </b-button></a
              >
            </div>
          </div>
          <div v-if="sites.length === 0 && !loading.page">
            <CenterMessage message="No sites found."></CenterMessage>
          </div>
          <div v-else class="text-start">
            <b-table
              :responsive="true"
              small
              hover
              v-if="!loading.page"
              :items="sites"
              :fields="fields"
            >
              <template #cell(action)="data">
                <div>
                  <a :href="'/sites/view/' + data.item.id">View</a>
                </div>
              </template>
            </b-table>
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import SiteService from "@/services/SiteService";
import CenterMessage from "@/components/CenterMessage.vue";

export default {
  name: "ListSitesView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: ["id", "url", "name", "description", "action"],
      sites: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
      sort: {
        column: "name",
        direction: "asc",
      },
    };
  },

  async mounted() {
    await this.getSites();
    this.loading.page = false;
  },

  methods: {
    async getSites(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await SiteService.getSites(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.sort.column,
        this.sort.direction
      );

      this.sites = response.data.sites.data;
      this.pagination.totalRows = response.data.sites.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.sites.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getSites();
      }
    },
  },
};
</script>