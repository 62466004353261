<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>Raise a New Ticket</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit">
                <b-form-group
                  class="mb-3 mt-3 required"
                  id="client-group"
                  label="Client"
                  label-for="client"
                >
                  <b-form-select
                    @change="updateSelections"
                    :disabled="loading.form"
                    class="w-100"
                    id="client"
                    v-model="form.client_code"
                    :options="options.clients"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="mb-3 mt-3"
                  id="project-group"
                  label="Project"
                  label-for="project"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="project"
                    v-model="form.project_id"
                    :options="options.projects"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="mb-3 mt-3"
                  id="site-group"
                  label="Site"
                  label-for="site"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="site"
                    v-model="form.site_id"
                    :options="options.sites"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  id="title-group"
                  label="Title"
                  label-for="title"
                  class="required"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Enter the ticket title."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="mb-3 mt-3"
                  id="category-group"
                  label="Category"
                  label-for="category"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="category"
                    v-model="form.category_code"
                    :options="options.categories"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="description-group"
                  label="Description"
                  label-for="description"
                >
                  <Editor
                    :disabled="this.loading.form"
                    id="update"
                    v-model="form.message"
                    api-key="4rq3ibpfp8f38sdodmtvtrh8oczqt541wz9our5ey3o9xjlj"
                    :init="{
                      height: 150,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat ',
                    }"
                  />
                </b-form-group>
                <b-form-file
                  class="form-control mb-3 mt-2"
                  v-model="form.files"
                  placeholder="Attach Files"
                  multiple
                  plain
                  ref="attachments"
                ></b-form-file>

                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.is_private"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
                >
                  <span :class="form.is_private == '1' ? 'fw-bold' : ''"
                    >&nbsp; Private - Not visible to client.</span
                  >
                </b-form-checkbox>
                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Raise Ticket</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientService from "@/services/ClientService";
import ProjectService from "@/services/ProjectService";
import SiteService from "@/services/SiteService";
import TicketService from "@/services/TicketService";
import { formatErrors, numberWithCommas } from "@/utils/helpers";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "RaiseTicketView",
  components: {
    Editor,
  },
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        client_code: "",
        project_id: "",
        site_id: "",
        title: "",
        category_code: "",
        message: "",
        hourly_rate: "",
        time_budget: "",
        is_private: "0",
        files: [],
      },
      options: {
        clients: [{ value: "", text: "Select Client" }],
        projects: [{ value: "", text: "Select Project (Optional)" }],
        sites: [{ value: "", text: "Select Site (Optional)" }],
        categories: [{ value: "", text: "Select Category (Optional)" }],
      },
      success: "",
      errors: [],
    };
  },

  mounted() {
    this.getClients();
    this.getCategories();
    this.getProjects();
    this.getSites();
  },

  computed: {
    estimated_project_cost() {
      let estimated_cost = 0;

      if (this.form.hourly_rate && this.form.time_budget) {
        estimated_cost = this.form.hourly_rate * this.form.time_budget;
      }

      return "GHS " + numberWithCommas(estimated_cost);
    },
  },

  methods: {
    updateSelections() {
      this.getProjects();
      this.getSites();
    },

    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Build payload
      const formData = new FormData();
      formData.append("client_code", this.form.client_code);
      formData.append("project_id", this.form.project_id);
      formData.append("site_id", this.form.site_id);
      formData.append("title", this.form.title);
      formData.append("category_code", this.form.category_code);
      formData.append("message", this.form.message);
      formData.append("hourly_rate", this.form.hourly_rate);
      formData.append("time_budget", this.form.time_budget);
      formData.append("is_private", this.form.is_private);
      if (this.form.files) {
        for (let i = 0; i < this.form.files.length; i++) {
          let file = this.$refs["attachments"].files[i];
          formData.append("files[" + i + "]", file);
        }
      }

      // Submit form.
      TicketService.raiseTicket(formData)
        .then((response) => {
          this.success = response.data.message;
          this.$router.push("/tickets/view/" + response.data.ticket.id);
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    async getClients() {
      let response = await ClientService.getClients(1, 1000000, "name", "asc");
      this.options.clients = [
        this.options.clients[0],
        ...response.data.clients.data.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },

    async getProjects() {
      let response = await ProjectService.getProjects(
        1,
        1000000,
        "name",
        "asc",
        "",
        "",
        this.form.client_code
      );
      this.options.projects = [
        this.options.projects[0],
        ...response.data.projects.data.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.id}`,
          };
        }),
      ];
    },

    async getSites() {
      let response = await SiteService.getSites(
        1,
        1000000,
        "name",
        "asc",
        "",
        this.form.client_code
      );
      this.options.sites = [
        this.options.sites[0],
        ...response.data.sites.data.map((item) => {
          return {
            text: `${item.name} - ${item.url}`,
            value: `${item.id}`,
          };
        }),
      ];
    },

    async getCategories() {
      let response = await TicketService.getCategories();
      this.options.categories = [
        this.options.categories[0],
        ...response.data.ticket_categories.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },
  },
};
</script>

