var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading.page)?_c('CustomLoader',{attrs:{"center":true}}):_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-start"},[_c('h3',{staticClass:"mb-3"},[_vm._v("Time Tracking")])])]),(_vm.timeEntries.length === 0 && !_vm.loading.page)?_c('div',[_c('CenterMessage',{attrs:{"message":"No time entries found."}})],1):_c('div',{staticClass:"text-start"},[_vm._l((_vm.timeEntries),function(timeEntry,index){return _c('div',{key:timeEntry.id},[(
                index == 0 ||
                _vm.moment(_vm.timeEntries[index].created_at).format(
                  'dddd MMMM Do YYYY'
                ) !=
                  _vm.moment(_vm.timeEntries[index - 1].created_at).format(
                    'dddd MMMM Do YYYY'
                  )
              )?_c('div',[_c('div',{class:'mb-2 ' + (index != 0 ? 'mt-4' : '')},[_vm._v(" "+_vm._s(_vm.moment(_vm.timeEntries[index].start_time).format( "dddd MMMM Do, YYYY." ))+" ")])]):_vm._e(),_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',{staticClass:"text-start"},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.timeEntries[index].id))]),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.timeEntries[index].staff.name)+" ")]),_c('td',{staticStyle:{"width":"500px"}},[_c('a',{attrs:{"target":"_blank","href":'/tickets/view/' + _vm.timeEntries[index].ticket.id}},[_vm._v(_vm._s("T" + _vm.timeEntries[index].ticket.id + " - " + _vm.timeEntries[index].ticket.client.name + " - " + _vm.timeEntries[index].ticket.title))])]),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.moment(_vm.timeEntries[index].start_time).format("h:mm a"))+" ")]),_c('td',{staticClass:"text-nowrap"},[(!_vm.timeEntries[index].end_time)?_c('span',{staticClass:"text-muted"},[_vm._v("Ongoing")]):_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.timeEntries[index].end_time).format("h:mm a"))+" ")])]),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.convertSecondsToTime(_vm.timeEntries[index].duration))+" ")]),_c('td',{staticClass:"text-end"},[_c('a',{attrs:{"href":'/time-entries/view/' + _vm.timeEntries[index].id}},[_vm._v("Edit")])])])])])])}),_c('nav',[_c('ul',{staticClass:"pagination justify-content-center"},[(_vm.pagination.currentPage > 1)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#","aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage - 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]):_vm._e(),_vm._l((_vm.pagination.totalPages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{ active: page == _vm.pagination.currentPage }},[(
                    page < 3 ||
                    page > _vm.pagination.totalPages - 3 ||
                    (page < _vm.pagination.currentPage + 3 &&
                      page > _vm.pagination.currentPage - 3)
                  )?_c('a',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v(_vm._s(page))]):_vm._e()])}),(_vm.pagination.currentPage < _vm.pagination.totalPages)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"aria-label":"Next"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage + 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]):_vm._e()],2)])],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }