import { ApiService } from "./APIService";

export default {
    getClients(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null
    ) {
        // Generate url
        let url = 'api/staff/clients?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        return ApiService.get(url);
    },

    getClient(client_id) {
        return ApiService.get("/api/staff/clients/" + client_id);
    },

    addClient(payload) {
        return ApiService.post("api/staff/clients/add", payload);
    },

    updateClient(client_id, payload) {
        return ApiService.post("/api/staff/clients/" + client_id + "/update", payload);
    },

    addClientUser(client_id, payload) {
        return ApiService.post("/api/staff/clients/" + client_id + "/users/add", payload);
    },

    getClientUser(client_id, user_id) {
        return ApiService.get("/api/staff/clients/" + client_id + "/users/" + user_id);
    },

    updateClientUser(client_id, user_id, payload) {
        return ApiService.post("/api/staff/clients/" + client_id + "/users/" + user_id + "/update", payload);
    },

    getClientSLAOptions() {
        return ApiService.get("/api/staff/service-level-agreements/options");
    },

    addClientSLA(payload) {
        return ApiService.post("/api/staff/service-level-agreements", payload);
    },

    getClientSLA(sla_id) {
        return ApiService.get("/api/staff/service-level-agreements/" + sla_id);
    },

    updateClientSLA(sla_id, payload) {
        return ApiService.patch("/api/staff/service-level-agreements/" + sla_id, payload);
    }
}