<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4>Edit Time Entry</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit">
                <b-form-group
                  class="mb-3 mt-3 required"
                  id="ticket-group"
                  label="Ticket"
                  label-for="ticket"
                >
                  <b-form-select
                    :disabled="loading.form"
                    class="w-100"
                    id="ticket"
                    v-model="form.ticket_id"
                    :options="tickets"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="start-time-group"
                  label="Start Time"
                  label-for="start-time"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="start-time"
                    v-model="form.start_time"
                    type="text"
                    placeholder="YYYY-MM-DD HH:MM:SS"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="required"
                  id="end-time-group"
                  label="End Time"
                  label-for="end-time"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="end-time"
                    v-model="form.end_time"
                    type="text"
                    placeholder="YYYY-MM-DD HH:MM:SS"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="text-end">
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Update Time Entry</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from "@/services/TicketService";
import TimeTrackingService from "@/services/TimeTrackingService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "EditTimeEntryView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        ticket_id: "",
        start_time: "",
        end_time: "",
      },
      time_entry: {},
      tickets: [{ value: "", text: " -- Select a Ticket -- " }],
      success: "",
      errors: [],
    };
  },
  async mounted() {
    await this.getTimeEntry();
    await this.getTickets();
    this.loading.page = false;
  },
  methods: {
    async getTimeEntry() {
      this.loading.page = true;
      let response = await TimeTrackingService.getTimeEntry(
        this.$route.params.id
      );
      this.time_entry = response.data.time_entry;
      this.loading.page = false;

      // Set data
      this.form.ticket_id = this.time_entry.ticket_id;
      this.form.start_time = this.time_entry.start_time;
      this.form.end_time = this.time_entry.end_time;
    },

    async getTickets() {
      let response = await TicketService.getTickets(
        1,
        1000000,
        "title",
        "desc"
      );

      this.tickets = [
        this.tickets[0],
        ...response.data.tickets.data.map((item) => {
          return {
            text: `T${item.id} - ${item.client.name} - ${item.title}`,
            value: `${item.id}`,
          };
        }),
      ];
    },

    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      TimeTrackingService.updateTrackingEntry(this.$route.params.id, this.form)
        .then((response) => {
          this.success = response.data.message;
          this.time_entry = response.data.time_entry;
        })
        .catch((error) => {
          console.log(error);
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>