import { ApiService } from "./APIService";

export default {
    getTickets(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null,
        statusCode = null,
        typeCode = null,
        clientCode = null,
        isPrivate = null
    ) {
        // Generate url
        let url = 'api/staff/tickets?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        if (clientCode) {
            url += '&client_code=' + clientCode;
        }

        if (statusCode) {
            url += '&status_code=' + statusCode;
        }

        if (typeCode) {
            url += '&category_code=' + typeCode;
        }

        if (isPrivate) {
            url += '&is_private=' + isPrivate;
        }

        return ApiService.get(url);
    },

    getTicket(ticket_id) {
        return ApiService.get("api/staff/tickets/" + ticket_id);
    },

    raiseTicket(payload) {
        return ApiService.post("api/staff/tickets/raise", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    updateTicket(ticket_id, payload) {
        return ApiService.post("api/staff/tickets/" + ticket_id + "/update", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    getCategories() {
        return ApiService.get("api/staff/tickets/categories");
    },

    getStatuses() {
        return ApiService.get("api/staff/tickets/statuses");
    },
}