<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4 class="text-center">New Invoice</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <div class="row">
                  <div
                    class="col-md-6"
                    style="padding-left: 0px; padding-right: 0px"
                  >
                    <b-form-group
                      class="mb-3 mt-3 required"
                      id="client-group"
                      label="Client"
                      label-for="client"
                    >
                      <b-form-select
                        :disabled="loading.form"
                        class="w-100"
                        id="client"
                        v-model="form.client_code"
                        :options="options.clients"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="row invoice-item-header">
                  <div class="col-md-8">Item</div>
                  <div class="col-md-1">Hrs.</div>
                  <div class="col-md-1">Rate</div>
                  <div class="col-md-1">Amt.</div>
                  <div class="col-md-1"></div>
                </div>
                <div
                  class="row invoice-item mt-1"
                  v-for="lineItem in this.form.items"
                  :key="lineItem.id"
                >
                  <div class="col-md-8">
                    <b-form-input
                      :disabled="loading.form"
                      class=""
                      :id="'description-' + lineItem.id"
                      v-model="lineItem.description"
                      type="text"
                      placeholder="Description of service or product."
                      required
                    ></b-form-input>
                  </div>
                  <div class="col-md-1">
                    <b-form-input
                      @change="calculateLineItemAmounts"
                      :disabled="loading.form"
                      class=""
                      :id="'quantity-' + lineItem.id"
                      v-model="lineItem.quantity"
                      type="text"
                      placeholder="0"
                      required
                    ></b-form-input>
                  </div>
                  <div class="col-md-1">
                    <b-form-input
                      @change="calculateLineItemAmounts"
                      :disabled="loading.form"
                      class=""
                      :id="'rate-' + lineItem.id"
                      v-model="lineItem.rate"
                      type="text"
                      placeholder="0.00"
                      required
                    ></b-form-input>
                  </div>
                  <div class="col-md-1 text-center mt-2 mr-1 fw-bold">
                    {{ lineItem.amount }}
                  </div>
                  <div class="col-md-1 text-center mt-2 mr-1">
                    <b-icon
                      @click="
                        form.items.length > 1
                          ? deleteLineItem(lineItem.id)
                          : null
                      "
                      :class="form.items.length > 1 ? 'link' : 'text-muted'"
                      icon="trash-fill"
                      variant="danger"
                    ></b-icon>
                  </div>
                </div>
                <div class="row mt-1 mb-3">
                  <b-button
                    @click.prevent="addLineItem"
                    variant="outline-success btn-sm w-20"
                    ><span style="font-size: 1rem"
                      ><b-icon icon="plus"></b-icon></span
                    >Add Line Item</b-button
                  >
                </div>

                <div class="row">
                  <div class="col-md-5" style="padding: 0">
                    <div class="fw-bold font-sm">Notes</div>
                    <textarea
                      :disabled="loading.form"
                      class="mb-1 w-100"
                      rows="4"
                      id="notes"
                      v-model="form.notes"
                      type="text"
                      placeholder="Payment rates, terms or any additional information."
                    ></textarea>
                  </div>
                  <div class="col-md-3" style="padding: 0"></div>
                  <div class="col-md-4" style="padding: 0">
                    <div class="fw-bold font-sm mb-1">Totals</div>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Sub-Total</div>
                      </div>
                      <div class="col-md-7 text-end invoice-summary-value">
                        <span class="currency_code">GH₵</span>
                        <b> {{ subTotal }}</b>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Tax %</div>
                      </div>
                      <div class="col-md-7">
                        <b-form-input
                          style="font-size: 0.9rem; padding: 5px; height: 25px"
                          :disabled="loading.form"
                          class="text-end"
                          id="tax"
                          v-model="form.tax_percentage"
                          type="text"
                          placeholder="0"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Tax Amount</div>
                      </div>
                      <div class="col-md-7 text-end invoice-summary-value">
                        <span class="currency_code">GH₵</span>
                        <b> {{ taxAmount }}</b>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="invoice-summary-label">Total</div>
                      </div>
                      <div class="col-md-7 text-end invoice-summary-total">
                        <span class="currency_code">GH₵</span>
                        <b> {{ total }}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-end mt-2">
                  <div class="col-md-4" style="padding: 0">
                    <b-button
                      class="w-100"
                      size="sm"
                      :disabled="loading.form"
                      type="submit"
                      variant="primary"
                      >Send Invoice</b-button
                    >
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientService from "@/services/ClientService";
import InvoiceService from "@/services/InvoiceService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddInvoiceView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        client_code: "",
        items: [
          {
            id: 0,
            description: "",
            quantity: "",
            rate: "",
            amount: 0,
          },
        ],
        sub_total: 0,
        total: 0,
        type: "client",
        tax: "",
        tax_percentage: "",
        notes: "",
      },
      counts: {
        item: 1,
        id: 0,
      },
      success: "",
      errors: [],
      options: {
        clients: [{ value: "", text: "Select Client" }],
      },
    };
  },

  mounted() {
    this.getClients();
  },

  computed: {
    subTotal() {
      let subTotal = 0;

      for (let index = 0; index < this.form.items.length; index++) {
        subTotal += this.form.items[index].amount * 1;
      }
      return subTotal;
    },

    taxAmount() {
      return (this.subTotal * this.form.tax_percentage) / 100;
    },

    total() {
      return this.subTotal * 1 + this.taxAmount * 1;
    },
  },

  methods: {
    calculateLineItemAmounts() {
      for (let index = 0; index < this.form.items.length; index++) {
        this.form.items[index].amount =
          this.form.items[index].quantity * this.form.items[index].rate;
      }
    },

    addLineItem() {
      this.counts.id++;
      this.form.items.push({
        id: this.counts.id,
        description: "",
        quantity: "",
        rate: "",
        amount: 0,
      });
      this.counts.item++;
    },

    deleteLineItem(id) {
      var removeIndex = this.form.items.map((item) => item.id).indexOf(id);
      ~removeIndex && this.form.items.splice(removeIndex, 1);
      this.counts.item--;
    },

    onSubmit() {
      this.form.tax = this.taxAmount;
      this.form.total = this.total;
      this.form.sub_total = this.subTotal;
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      InvoiceService.addInvoice(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.name = "";
      this.form.description = "";
    },

    async getClients() {
      let response = await ClientService.getClients(1, 1000000, "name", "desc");
      this.options.clients = [
        this.options.clients[0],
        ...response.data.clients.data.map((item) => {
          return {
            text: `${item.name} ${
              item.hourly_rate != null
                ? " - GHS " + item.hourly_rate + " / hour"
                : ""
            }`,
            value: `${item.code}`,
          };
        }),
      ];
    },
  },
};
</script>


