<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <div class="row mb-2">
            <div class="col-md-12">
              <h4 class="mb-3">{{ client.name }} - Add SLA</h4>
              <b-alert variant="success" :show="success !== ''">{{
                success
              }}</b-alert>
              <b-alert variant="danger" :show="errors.length > 0">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </b-alert>
              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
                <div class="row">
                  <div class="col-md-6 mt-0">
                    <b-form-group
                      class="mb-3 required"
                      id="billing-period-group"
                      label="Billing Frequency"
                      label-for="billing_period_code"
                    >
                      <b-form-select
                        :disabled="loading.form"
                        class="w-100"
                        id="billing_period_code"
                        v-model="form.billing_period_code"
                        :options="options.billing_periods"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      id="next-billing-date"
                      label="Next Billing Date"
                      label-for="next_billing_date"
                      class="required"
                    >
                      <b-form-input
                        :disabled="loading.form"
                        class="mb-3"
                        id="next_billing_date"
                        v-model="form.next_billing_date"
                        type="date"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mt-0">
                    <b-form-group
                      class="mb-3 required"
                      id="monthly-amount-group"
                      label="Monthly Amount"
                      label-for="monthly_amount"
                    >
                      <b-form-input
                        :disabled="loading.form"
                        class="mb-3"
                        id="monthly_amount"
                        v-model="form.monthly_amount"
                        type="number"
                        placeholder="0.00"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      id="status-group"
                      label="Status"
                      label-for="status_code"
                      class="required"
                    >
                      <b-form-select
                        :disabled="loading.form"
                        class="w-100"
                        id="status_code"
                        v-model="form.status_code"
                        :options="options.statuses"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  id="sla-document-link-group"
                  label="SLA Document Link"
                  label-for="sla_document_link"
                >
                  <b-form-input
                    :disabled="loading.form"
                    class="mb-3"
                    id="sla_document_link"
                    v-model="form.sla_document_link"
                    type="text"
                    placeholder="Enter link to SLA PDF Document."
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="text-end">
                  <a :href="`/clients/view/${this.$route.params.clientId}`">
                    <b-button size="sm" variant="dark"
                      >Back to Client</b-button
                    ></a
                  >
                  &nbsp;
                  <b-button
                    size="sm"
                    :disabled="loading.form"
                    type="submit"
                    variant="primary"
                    >Save Service Level Agreement</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { formatErrors } from "@/utils/helpers";
import ClientService from "@/services/ClientService";

export default {
  name: "AddClientUserView",
  data() {
    return {
      loading: {
        page: true,
        form: false,
      },
      form: {
        client_code: "",
        monthly_amount: "",
        sla_document_link: "",
        billing_period_code: "",
        next_billing_date: "",
        status_code: "",
      },
      options: {
        billing_periods: [{ value: "", text: "Select Billing Period" }],
        statuses: [{ value: "", text: "Select Status" }],
      },
      client: {},
      success: "",
      errors: [],
    };
  },

  mounted() {
    this.getClient();
    this.getSLAOptions();
  },

  methods: {
    async getClient() {
      let response = await ClientService.getClient(this.$route.params.clientId);
      this.client = response.data.client;
      this.form.client_code = this.client.code;
    },

    async getSLAOptions() {
      let response = await ClientService.getClientSLAOptions();
      this.options.billing_periods = [
        this.options.billing_periods[0],
        ...response.data.options.billing_periods.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
      this.options.statuses = [
        this.options.statuses[0],
        ...response.data.options.statuses.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    },
    onSubmit() {
      // Clear errors
      this.success = "";
      this.errors = [];

      // Set loading state
      this.loading.form = true;

      // Submit form.
      ClientService.addClientSLA(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.onReset();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onReset() {
      // Reset our form values
      this.form.monthly_amount = "";
      this.form.sla_document_link = "";
      this.form.billing_period_code = "monthly";
      this.form.next_billing_date = "";
      this.form.status_code = "pending_client_approval";
    },
  },
};
</script>
  
  