import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import LoginView from '../views/LoginView.vue'
import ListTicketsView from '@/views/tickets/ListTicketsView.vue'
import ListAccountsView from '@/views/accounts/ListAccountsView.vue'
import ViewAccountView from '@/views/accounts/ViewAccountView.vue'
import AddClientView from '@/views/clients/AddClientView.vue'
import ListClientsView from '@/views/clients/ListClientsView.vue'
import ViewClientView from '@/views/clients/ViewClientView.vue'
import AddInvoiceView from '@/views/invoices/AddInvoiceView.vue'
import ListInvoicesView from '@/views/invoices/ListInvoicesView.vue'
import ViewInvoiceView from '@/views/invoices/ViewInvoiceView.vue'
import AddProjectView from '@/views/projects/AddProjectView.vue'
import ListProjectsView from '@/views/projects/ListProjectsView.vue'
import ViewProjectView from '@/views/projects/ViewProjectView.vue'
import AddSettingView from '@/views/settings/AddSettingView.vue'
import ListSettingsView from '@/views/settings/ListSettingsView.vue'
import ViewSettingView from '@/views/settings/ViewSettingView.vue'
import AddSiteView from '@/views/sites/AddSiteView.vue'
import ListSitesView from '@/views/sites/ListSitesView.vue'
import ViewSiteView from '@/views/sites/ViewSiteView.vue'
import AddStaffView from '@/views/staff/AddStaffView.vue'
import ListStaffView from '@/views/staff/ListStaffView.vue'
import ViewStaffView from '@/views/staff/ViewStaffView.vue'
import RaiseTicketView from '@/views/tickets/RaiseTicketView.vue'
import ViewTicketView from '@/views/tickets/ViewTicketView.vue'
import ListTimeEntriesView from '@/views/time/ListTimeEntriesView.vue'
import EditTimeEntryView from '@/views/time/EditTimeEntryView.vue'
import RecordTransactionView from '@/views/accounts/RecordTransactionView.vue';
import AddClientUserView from '@/views/clients/users/AddClientUserView.vue';
import ViewClientUserView from '@/views/clients/users/ViewClientUserView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import AddClientSLAView from '@/views/clients/service-level-agreements/AddClientSLAView.vue';
import ViewClientSLAView from '@/views/clients/service-level-agreements/ViewClientSLAView.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    name: '404',
    component: NotFoundView,
    meta: {
      title: 'Resource Not Found.',
      public: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login',
      public: true
    }
  },
  {
    path: '/',
    name: 'tickets',
    component: ListTicketsView,
    meta: {
      title: 'Tickets',
    }
  },
  {
    path: '/accounts',
    name: 'list-accounts',
    component: ListAccountsView,
    meta: {
      title: 'Accounts',
    }
  },
  {
    path: '/accounts/record-transaction',
    name: 'record-transaction',
    component: RecordTransactionView,
    meta: {
      title: 'Record Transaction',
    }
  },
  {
    path: '/accounts/view/:id',
    name: 'view-account',
    component: ViewAccountView,
    meta: {
      title: 'View Account',
    }
  },
  {
    path: '/clients/add',
    name: 'add-client',
    component: AddClientView,
    meta: {
      title: 'Add Client',
    }
  },
  {
    path: '/clients',
    name: 'list-clients',
    component: ListClientsView,
    meta: {
      title: 'Clients',
    }
  },
  {
    path: '/clients/view/:id',
    name: 'view-client',
    component: ViewClientView,
    meta: {
      title: 'View Client',
    }
  },

  {
    path: '/clients/:clientId/users/add',
    name: 'add-client-user',
    component: AddClientUserView,
    meta: {
      title: 'Add Client User',
    }
  },
  {
    path: '/clients/:clientId/users/:userId',
    name: 'vieew-client-user',
    component: ViewClientUserView,
    meta: {
      title: 'View Client User',
    }
  },
  {
    path: '/clients/:clientId/service-level-agreements/add',
    name: 'add-client-sla',
    component: AddClientSLAView,
    meta: {
      title: 'Add Client SLA',
    }
  },
  {
    path: '/clients/:clientId/service-level-agreements/:slaId',
    name: 'vieew-client-sla',
    component: ViewClientSLAView,
    meta: {
      title: 'View Client SLA',
    }
  },
  {
    path: '/invoices/add',
    name: 'add-invoice',
    component: AddInvoiceView,
    meta: {
      title: 'Add Invoice',
    }
  },
  {
    path: '/invoices',
    name: 'list-invoices',
    component: ListInvoicesView,
    meta: {
      title: 'Invoices',
    }
  },
  {
    path: '/invoices/view/:id',
    name: 'view-invoice',
    component: ViewInvoiceView,
    meta: {
      title: 'View Invoice',
    }
  },
  {
    path: '/projects/add',
    name: 'add-project',
    component: AddProjectView,
    meta: {
      title: 'Add Project',
    }
  },
  {
    path: '/projects',
    name: 'list-projects',
    component: ListProjectsView,
    meta: {
      title: 'Projects',
    }
  },
  {
    path: '/projects/view/:id',
    name: 'view-project',
    component: ViewProjectView,
    meta: {
      title: 'View Project',
    }
  },
  {
    path: '/settings/add',
    name: 'add-setting',
    component: AddSettingView,
    meta: {
      title: 'Add Setting',
    }
  },
  {
    path: '/settings',
    name: 'list-settings',
    component: ListSettingsView,
    meta: {
      title: 'Settings',
    }
  },
  {
    path: '/settings/view/:id',
    name: 'view-setting',
    component: ViewSettingView,
    meta: {
      title: 'View Setting',
    }
  },
  {
    path: '/sites/add',
    name: 'add-site',
    component: AddSiteView,
    meta: {
      title: 'Add Site',
    }
  },
  {
    path: '/sites',
    name: 'list-sites',
    component: ListSitesView,
    meta: {
      title: 'Sites',
    }
  },
  {
    path: '/sites/view/:id',
    name: 'view-site',
    component: ViewSiteView,
    meta: {
      title: 'View Site',
    }
  },
  {
    path: '/staff/add',
    name: 'add-staff',
    component: AddStaffView,
    meta: {
      title: 'Add Staff',
    }
  },
  {
    path: '/staff',
    name: 'list-staff',
    component: ListStaffView,
    meta: {
      title: 'Staff',
    }
  },
  {
    path: '/staff/view/:id',
    name: 'view-staff',
    component: ViewStaffView,
    meta: {
      title: 'View Staff',
    }
  },
  {
    path: '/tickets/raise',
    name: 'add-ticket',
    component: RaiseTicketView,
    meta: {
      title: 'Raise Ticket',
    }
  },
  {
    path: '/tickets',
    name: 'list-tickets',
    component: ListTicketsView,
    meta: {
      title: 'Tickets',
    }
  },
  {
    path: '/tickets/view/:id',
    name: 'view-ticket',
    component: ViewTicketView,
    meta: {
      title: 'View Ticket',
    }
  },
  {
    path: '/time-entries',
    name: 'list-time-entries',
    component: ListTimeEntriesView,
    meta: {
      title: 'Time Entries',
    }
  },
  {
    path: '/time-entries/view/:id',
    name: 'edit-time-entry',
    component: EditTimeEntryView,
    meta: {
      title: 'Edit Time Entry',
    }
  },
]

const DEFAULT_TITLE = 'Staff Portal';


const router = new VueRouter({
  mode: 'history',
  routes
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = 'Staff - ' + to.meta.title || DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  const authenticatedUser = store.getters["user/user"];
  const openRoute = to.matched.some((record) => record.meta.public);
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  const homeQuery = { path: "/tickets" };

  if (!openRoute && !authenticatedUser) {
    next(loginQuery);
  } else if (openRoute && authenticatedUser) {
    next(homeQuery);
  } else {
    next();
  }
});

export default router
