<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="row mb-2">
            <div class="col-md-6 text-start">
              <h3>Invoices</h3>
            </div>
            <div class="col-md-6 text-end">
              <a href="/invoices/add"
                ><b-button size="sm" class="fw-bold" variant="primary">
                  New Invoice
                </b-button></a
              >
            </div>
          </div>
          <div v-if="invoices.length === 0 && !loading.page">
            <CenterMessage message="No invoices found."></CenterMessage>
          </div>
          <div v-else class="text-start">
            <b-table
              :responsive="true"
              small
              hover
              v-if="!loading.page"
              :items="invoices"
              :fields="fields"
            >
              <template #cell(entity)="data">
                <div>
                  <span v-if="data.item.type == 'client'">
                    <b> {{ data.item.client.name }}</b>
                  </span>
                  <span v-else>{{
                    data.item.staff.name
                  }}</span>
                </div>
              </template>
              <template #cell(sub_total)="data">
                <div>
                  <span v-if="data.item.sub_total">
                    <b> {{ numberWithCommas(data.item.sub_total) }}</b>
                  </span>
                  <span v-else class="text-muted text-sm">Not set. </span>
                </div>
              </template>
              <template #cell(total)="data">
                <div>
                  <span v-if="data.item.total">
                    <b> {{ numberWithCommas(data.item.total) }}</b>
                  </span>
                  <span v-else class="text-muted text-sm">Not set. </span>
                </div>
              </template>
              <template #cell(tax)="data">
                <div>
                  <span v-if="data.item.tax">
                    <b> {{ numberWithCommas(data.item.tax) }}</b>
                  </span>
                  <span v-else class="text-muted text-sm">Not set. </span>
                </div> </template
              ><template #cell(status)="data">
                <div>
                  <span
                    class="invoice-summary-value text-danger"
                    v-if="data.item.status_code == 'pending'"
                    >UNPAID
                  </span>
                  <span
                    class="invoice-summary-value text-warning"
                    v-if="data.item.status_code == 'part_paid'"
                    >PART PAID
                  </span>
                  <span
                    class="invoice-summary-value text-success"
                    v-if="data.item.status_code == 'paid'"
                    >PAID
                  </span>
                  <span
                    class="invoice-summary-value text-dark"
                    v-if="data.item.status_code == 'cancelled'"
                    >CANCELLED
                  </span>
                </div>
              </template>
              <template #cell(date)="data">
                <div>
                  <span
                    >{{
                      moment(data.item.created_at).format(
                        "dddd MMMM Do YYYY, h:mm a"
                      )
                    }}
                  </span>
                </div>
              </template>
              <template #cell(action)="data">
                <div>
                  <a :href="'/invoices/view/' + data.item.id">View</a>
                </div>
              </template>
            </b-table>
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import InvoiceService from "@/services/InvoiceService";
import CenterMessage from "@/components/CenterMessage.vue";

export default {
  name: "ListInvoicesView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "id",
        "date",
        "entity",
        "type",
        "sub_total",
        "tax",
        "total",
        "status",
        "action",
      ],
      invoices: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
      sort: {
        column: "created_at",
        direction: "desc",
      },
    };
  },

  async mounted() {
    await this.getInvoices();
    this.loading.page = false;
  },

  methods: {
    async getInvoices(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await InvoiceService.getInvoices(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.sort.column,
        this.sort.direction
      );

      this.invoices = response.data.invoices.data;
      this.pagination.totalRows = response.data.invoices.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.invoices.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getInvoices();
      }
    },
  },
};
</script>