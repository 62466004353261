<template>
  <div>
    <CustomLoader v-if="loading.page" :center="true"></CustomLoader>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-6 text-start">
              <h3>Tickets</h3>
            </div>
            <div class="col-md-6 text-end">
              <a href="/tickets/raise"
                ><b-button size="sm" class="fw-bold" variant="primary">
                  Raise Ticket
                </b-button></a
              >
            </div>
          </div>
          <div class="col-md-12 mb-3 p-2 text-start box-shadow-light">
            <div class="row">
              <div class="col-md-3">
                <b-form-select
                  :disabled="loading.page"
                  @change="getTickets"
                  class="w-100"
                  id="status"
                  v-model="filter.status"
                  :options="options.statuses"
                ></b-form-select>
              </div>
              <div class="col-md-3">
                <b-form-select
                  :disabled="loading.page"
                  @change="getTickets"
                  class="w-100"
                  id="type"
                  v-model="filter.type"
                  :options="options.types"
                ></b-form-select>
              </div>
              <div class="col-md-3">
                <b-form-select
                  :disabled="loading.page"
                  @change="getTickets"
                  class="w-100"
                  id="client"
                  v-model="filter.client"
                  :options="options.clients"
                ></b-form-select>
              </div>
              <div class="col-md-3">
                <b-form-select
                  :disabled="loading.page"
                  @change="getTickets"
                  class="w-100"
                  id="sort"
                  v-model="filter.sort"
                  :options="options.sort"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div v-if="tickets.length === 0 && !loading.page">
            <CenterMessage message="No tickets found."></CenterMessage>
          </div>
          <div v-else class="text-start">
            <b-table
              :responsive="true"
              small
              hover
              v-if="!loading.page"
              :items="tickets"
              :fields="fields"
            >
              <template #cell(id)="data">
                <div
                  :class="
                    'text-nowrap' +
                    (data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : '')
                  "
                >
                  {{ data.item.id }}
                </div>
              </template>
              <template #cell(title)="data">
                <div
                  :class="
                    data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : ''
                  "
                >
                  {{ data.item.title }}
                </div>
              </template>
              <template #cell(client)="data">
                <div
                  :class="
                    'text-nowrap' +
                    (data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : '')
                  "
                >
                  {{ data.item.client.name }}
                </div>
              </template>
              <template #cell(category)="data">
                <div
                  :class="
                    'text-nowrap' +
                    (data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : '')
                  "
                >
                  <span v-if="data.item.category_code != null"
                    >{{ data.item.category.name }}
                  </span>
                  <span v-else class="text-muted text-sm">Not set. </span>
                </div>
              </template>
              <template #cell(status)="data">
                <div
                  :class="
                    'text-nowrap' +
                    (data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : '')
                  "
                >
                  {{ data.item.status.name }}
                </div>
              </template>
              <template #cell(last_updated)="data">
                <div
                  :class="
                    'text-nowrap' +
                    (data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : '')
                  "
                >
                  {{ new Date(data.item.updated_at).toLocaleString("nl-NL") }}
                </div>
              </template>
              <template #cell(action)="data">
                <div
                  :class="
                    data.item.status_code == 'completed' ||
                    data.item.status_code == 'cancelled'
                      ? ' text-muted'
                      : ''
                  "
                >
                  <a :href="'/tickets/view/' + data.item.id">View</a>
                </div>
              </template>
            </b-table>
            <nav>
              <ul class="pagination justify-content-center">
                <li v-if="pagination.currentPage > 1" class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(pagination.currentPage - 1)"
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="page in pagination.totalPages"
                  :key="page"
                  :class="{ active: page == pagination.currentPage }"
                >
                  <a
                    v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    "
                    class="page-link"
                    @click.prevent="changePage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  v-if="pagination.currentPage < pagination.totalPages"
                  class="page-item"
                >
                  <a
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="changePage(pagination.currentPage + 1)"
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/CustomLoader";
import TicketService from "@/services/TicketService";
import CenterMessage from "@/components/CenterMessage.vue";
import ClientService from "@/services/ClientService";

export default {
  name: "ListTicketsView",
  components: {
    CustomLoader,
    CenterMessage,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      fields: [
        "id",
        "client",
        "title",
        "category",
        "status",
        "last_updated",
        "action",
      ],
      tickets: [],
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0,
        totalPages: 0,
      },
      filter: {
        client: "",
        type: "",
        status: "",
        sort: "sort-time-desc",
      },
      sort: {
        column: "updated_at",
        direction: "asc",
      },
      options: {
        clients: [{ value: "", text: "Filter by Client" }],
        types: [
          { value: "", text: "Filter by Category" },
          { value: "bug_report", text: "Bug Report" },
          { value: "change_request", text: "Change Request" },
          { value: "new_work", text: "New Work" },
        ],
        statuses: [
          { value: "", text: "Filter by Status" },
          { value: "pending", text: "Pending" },
          {
            value: "quoted_pending_approval",
            text: "Quoted, pending approval",
          },
          { value: "ongoing", text: "Ongoing" },
          { value: "in_review", text: "In Review" },
          { value: "completed", text: "Completed" },
          { value: "cancelled", text: "Cancelled" },
        ],
        sort: [
          { value: "sort-time-asc", text: "Sort by Update Time, Asc" },
          { value: "sort-time-desc", text: "Sort by Update Time, Desc" },
        ],
      },
    };
  },

  async mounted() {
    await this.getTickets();
    ClientService.getClients(1, 1000000, "name", "asc").then((response) => {
      this.options.clients = [
        this.options.clients[0],
        ...response.data.clients.data.map((item) => {
          return {
            text: `${item.name}`,
            value: `${item.code}`,
          };
        }),
      ];
    });

    this.loading.page = false;
  },

  methods: {
    async getTickets(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      if (this.filter.sort == "sort-time-asc") {
        this.sort.direction = "asc";
      } else {
        this.sort.direction = "desc";
      }

      let response = await TicketService.getTickets(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.sort.column,
        this.sort.direction,
        "",
        this.filter.status,
        this.filter.type,
        this.filter.client
      );

      this.tickets = response.data.tickets.data;
      this.pagination.totalRows = response.data.tickets.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.tickets.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getTickets();
      }
    },
  },
};
</script>