import store from "@/store";
import UserService from "@/services/UserService";

export default {
     async getAccessToken(type) {
         let token;
         switch (type){
             case 'user':
                 token = store.getters["user/userToken"];
                 break;
             case 'caretaker':
                 token = store.getters["user/caretakerToken"];

                 if (token) {
                     return token;
                 }

                 // Generate caretaker token
                 token = await UserService.generateToken({
                     email: process.env.VUE_APP_CARETAKER_EMAIL,
                     password: process.env.VUE_APP_CARETAKER_PASSWORD,
                     device_name: navigator.userAgent,
                 })

                 token = token.data.token;

                 // Set caretaker token
                 store.commit('user/SET_CARETAKER_TOKEN', token);

                 break;
         }

         return token
     }
}