import axios from "axios";
import TokenService from "@/services/TokenService";
import store from "@/store";
import router from "@/router";

export const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});


// interceptors to add token
ApiService.interceptors.request.use(
  async (config) => {
    const token = await TokenService.getAccessToken('user');

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiService.interceptors.response.use(
  undefined, async (error) => {
    if (error.response.status === 419 || error.response.status === 401) {
      // Unauthenticated - Delete user details
      await store.dispatch('user/logout', null);
      await router.push('/login');
    }
    if (error.response.status === 404) {
      await router.push('/404');
    }
    return Promise.reject(error);
  }
);
