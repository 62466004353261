import { ApiService } from "./APIService";

export default {
    getProjects(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
        searchText = null,
        statusCode = null,
        clientCode = null
    ) {

        // Generate url
        let url = 'api/staff/projects?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        // Optional parameters
        if (searchText) {
            url += '&search_text=' + searchText;
        }

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        if (statusCode) {
            url += '&status_code=' + statusCode;
        }

        if (clientCode) {
            url += '&client_code=' + clientCode;
        }

        return ApiService.get(url);
    },

    getProject(project_id) {
        return ApiService.get("api/staff/projects/" + project_id);
    },

    addProject(payload) {
        return ApiService.post("api/staff/projects/add", payload);
    },

    updateProject(project_id, payload) {
        return ApiService.post("/api/staff/projects/" + project_id + "/update", payload);
    },

    getStatuses() {
        return ApiService.get("api/staff/projects/statuses");
    },
}